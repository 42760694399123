<template>
  <div class="chat-robot-box text-message">
    <span v-for="(item, idx) in message.content" :key="idx">
      <span
        v-if="item.action"
        style="font-size: 105%; color: #3a95fa; text-decoration: underline; margin-left: 0.25em; margin-right: 0.25em;"
        @click="doAction(item.action)"
        >{{ item.text }}</span
      >
      <span v-else-if="item.type !== 'html'">{{ item.text }}</span>
      <span v-else v-html="item.text">123</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BubbleAnchor',
  props: {
    message: Object
  },
  methods: {
    doAction (fn) {
      fn.call(this, this.$store)
    }
  }
}
</script>

<style></style>
